// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import '../js/main.js'

import '../js/category.js'

// autocomplete
import "jquery-ui/ui/widgets/autocomplete.js"
import "jquery-ui/themes/base/autocomplete.css"
import '../js/autocomplete.js'

// foundation
import "foundation-sites"
import 'foundation-sites/dist/css/foundation.css'
import '../js/foundation.js'

// owl
import 'owl.carousel/dist/owl.carousel.js'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import 'owl.carousel/dist/assets/owl.carousel.css'
import '../js/owl.js'

// photoswipe
import 'photoswipe/dist/photoswipe.js'
import 'photoswipe/src/css/main.scss'
import 'photoswipe/src/css/default-skin/default-skin.scss'
import '../js/photoswipe.js'

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import '../styles/application.sass'

