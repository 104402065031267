$(document).on('turbolinks:load', function() {
  loadSearchBar();

  var address = window.location.origin;

  function loadSearchBar() {
    $("#searchbar").mouseover(function () {
      $(this).select();
      $('.search-bar').addClass('active');
    });

    $("#searchbar").focusout(function () {
      $(this).val("");
      $('.search-bar').removeClass('active');
    });

    var cache = {};
    $("#searchbar").autocomplete({
      minLength: 1,
      delay: 0,
      messages: {
        noResults: '',
        results: function() {}
      },
      source: function(request, response) {
        var term = request.term;
        if (term in cache) {
          response(cache[term]);
          return;
        }
        $.ajax({
          url: "/search",
          dataType: "json",
          data: {
            term: request.term
          }, success: function(data) {
            response(data);
          }
        });
      },
      focus: function(event, ui) {
        if (!ui.item.notfound && ui.item.notfound == undefined) {
          $("#searchbar").val(ui.item.title);
        }
        return false;
      },
      select: function(event, ui) {
        if (!ui.item.notfound && ui.item.notfound == undefined) {
          $("#searchbar").val(ui.item.title);
        }
        return false;
      },
      response: function(event, ui) {
        if (!ui.content.length) {
          var noResult = {
            notfound: true,
            title: "Aucun résultat trouvé"
          };
          ui.content.push(noResult);
        }
      },
    })
      .focus(function () {
        $(this).autocomplete("search", "");
      })
      .autocomplete("instance")._renderItem = function(ul, item) {
      if (item.slug !== undefined)
        return $("<li class='autocomplete-project'>")
          .append(`<a class='link' href="${address}/projets/${item.slug}">${item.title}</a>`)
          .appendTo(ul);
      else {
        return $("<li class='autocomplete-project'>")
          .append(`<p>Aucun résultat trouvé</p>`)
          .appendTo(ul);
      }
    };
  }
});
