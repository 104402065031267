$(document).on('turbolinks:load', function() {
  let runOwl = function() {
    let stage = $('.owl-stage');
    let stageWidth = stage.width();
    let elem = $('.owl-item');
    let elemWidth = 0;
    elem.each(function() {
      elemWidth += $(this).width() + ($(this).css("margin-right").slice(0, -2))
    });
    if (elemWidth > stageWidth) {
      stage.width(elemWidth);
    }
  };

  setInterval(function() {
    if ($('.owl-carousel').length) {
      $('#owl').owlCarousel({
        autoPlay: 3000,
        margin: 20,
        loop: false,
        autoWidth: true,
        items: 1,
        nav: true,
        navText: ['', ''],
        0 : {
          margin:10,
          loop:false,
          autoWidth:false,
          items:1,
          nav: false,
          dots: false
        },
        // breakpoint from 480 up
        480 : {
          margin:10,
          loop:false,
          autoWidth:false,
          items:1,
          nav: false,
          dots: false
        },
        // breakpoint from 768 up
        768 : {
          margin:20,
          loop:false,
          autoWidth:true,
          items:2,
          nav: true,
          navText: ['','']
        },
        onInitialized: runOwl,
        onRefreshed: runOwl
      })
    }
  }, 2000)
});
