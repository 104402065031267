$(document).on('turbolinks:load', function() {
  if (window.location.hash) {
    $(".menu > li.projet").click(function(e) {
      e.preventDefault();

      $(".menu > li.projet").removeClass('black-link').addClass('grey-link');
      $(this).addClass('black-link');

      let url = $(this)[0].children[0].getAttribute('href');
      history.pushState(null, '', url);

      let category = $(this).data("category");

      $('.my-project').each(function(index, el) {
        let project_categories = $(el).data('category').split(',');

        if (project_categories.indexOf(category) > -1) {
          $(el).css('visibility', 'visible');
          $(el).css('display', 'block');
        } else {
          $(el).css('visibility', 'hidden');
          $(el).css('display', 'none');
        }
      });
    });
  } else {
    $(".menu > li.projet").click(function() {
      let index = $(this).index();
      function clickPlease() {
        $($(".menu > li.projet")[index - 2]).click();
      }

      setTimeout(clickPlease, 200);
    });
  }
});