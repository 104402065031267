$(document).on('turbolinks:load', function() {
  secureMail();

  $(".bow-tie").each(function () {
    $(this).find('.caption').height($(this).height());
  });

  function secureMail() {
    $('a[title*="[at]"][title*="[dot]"]').each(function() {
      var email = $(this).attr('title').split('[at]').join('@').split('[dot]').join('.');
      $(this).attr('href', 'mailto:' + email.toLowerCase());
      $(this).attr('title', '');
      if ($(this).text().length === 0) $(this).text(email);
    });
  }
});
