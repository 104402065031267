import * as PhotoSwipe from 'photoswipe'
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

$(document).on('turbolinks:load', function() {
  var items = [];

  $('.my-photoswipe .item').each(function () {
    let link = $(this).find('a'),
      item = {
        src: link.attr('href'),
        w: link.data('width'),
        h: link.data('height'),
        title: link.data('caption')
      };
    items.push(item);
  });

  let pswpElement = $('.pswp')[0];

  $('.my-photoswipe a').click(function (event) {
    event.preventDefault();

    let ind = $(this).closest('.owl-item').index();

    window.location.hash = ind;

    let options = {
      index: ind,
      bgOpacity: 0.85,
      loop: false
    };

    let gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
    gallery.options.history = false;
    gallery.options.arrowKeys = false;

    disableArrows();
  });

  function disableArrows() {
    let text = $('.pswp__counter').html();
    let arr = text.split('/');
    let currentSlide = parseInt(arr[0]);
    let slidesLength = parseInt(arr[1]);

    if (currentSlide === 1) {
      $('.pswp__button--arrow--left').css('display', 'none');
    } else {
      $('.pswp__button--arrow--left').css('display', 'block');
    }

    if (currentSlide === slidesLength) {
      $('.pswp__button--arrow--right').css('display', 'none');
    } else {
      $('.pswp__button--arrow--right').css('display', 'block');
    }
  }

  $('.pswp__button--arrow--right').click(function () {
    disableArrows();
  });

  $('.pswp__button--arrow--left').click(function () {
    disableArrows();
  });
});